<template>
  <div
    class="tendency-wrap"
    v-loading.fullscreen="loading"
    element-loading-text="加载中"
  >
    <div class="tendency-search">
      <input
        type="text"
        v-model="search"
        @keyup.enter="searchHandle"
        placeholder="请输入品牌名或关键词搜索"
      />
      <span
        @click="searchHandle"
        class="pointer"
      ><i class="icon-search"></i> 搜索</span>
    </div>
    <div class="tendency-content">
      <div class="tendency-left">
        <div class="tendency-Carousel">
          <el-carousel
            height="336px"
            :interval=50000
          >
            <el-carousel-item
              v-for="(item,index) in newsFocus"
              :key="index"
            >
              <div
                class="tendency-Carousel-item"
                @click="toDetail(item)"
              >
                <img :src="item.pic" />
                <div>
                  <p>{{item.title}}</p>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="tendency-article">
          <h2 class="tendency-title"><i></i>商业洞察</h2>
          <ul class="tendency-label">
            <li
              v-for="item in labelList.slice(0,8)"
              :key="item.label"
              class="tendency-label-text"
              @click="selectHandle(item)"
              :class="[item.label===selectLabel.label?'active':'']"
            >{{item.label}}</li>
            <template v-if="isShow">
              <li
                v-for="item in labelList.slice(8,labelList.length)"
                :key="item.label"
                class="tendency-label-text"
                @click="selectHandle(item)"
                :class="[item.label===selectLabel.label?'active':'']"
              >{{item.label}}</li>
            </template>
            <li
              class="tendency-label-switch"
              @click="hideAndShowLabel"
            ><i :class="[isShow?'icon-shrink':'icon-grow']"></i>{{isShow?'收起':'展开'}}</li>
          </ul>
          <news-item
            v-for="item in tendencyList.slice(0,5)"
            :key="item.newsId"
            :detail="item"
            title='商业洞察列表'
          ></news-item>
          <!-- <div class="brand-tendency-list">
              <div
                class="brand-item"
                v-for="item in brandList.slice(0,2)"
                :key="item.brandId">
                <div class="brand-item-info" @click="toBrandDetail(item)">
                  <div class="brand-img pointer"><img :src="item.brandLogo"/></div>
                  <h4>{{item.brandName}}{{item.brandEnglishName}}</h4>
                </div>
                <p @click="toDetail(news)" v-for="news in item.newsList" :key="news.newsId"><span>{{news.title}}</span><i class="el-icon-arrow-right "></i></p>
              </div>
            </div> -->
          <news-item
            v-for="item in tendencyList.slice(5,10)"
            :key="item.newsId"
            :detail="item"
            title='商业洞察列表'
          ></news-item>
          <!-- <div class="brand-tendency-list">
              <div
                class="brand-item"
                v-for="item in brandList.slice(2,4)"
                :key="item.brandId">
                <div class="brand-item-info" @click="toBrandDetail(item)">
                  <div class="brand-img pointer"><img :src="item.brandLogo"/></div>
                  <h4>{{item.brandName}}{{item.brandEnglishName}}</h4>
                </div>
                <p @click="toDetail(news)" v-for="news in item.newsList" :key="news.newsId"><span>{{news.title}}</span><i class="el-icon-arrow-right "></i></p>
              </div>
            </div> -->
          <news-item
            v-for="item in tendencyList.slice(10,15)"
            :key="item.newsId"
            :detail="item"
            title='商业洞察列表'
          ></news-item>
          <div class="see-more">
            <span @click="toList">查看更多</span>
          </div>
        </div>
      </div>
      <div class="tendency-right">
        <brand-top class="mb-20"></brand-top>
        <brand-inventory class="mb-20"></brand-inventory>
        <brand-tab></brand-tab>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import brandTop from './brandTop.vue'
import newsItem from './newsItem.vue'
import brandTab from './brandTab'
import brandInventory from './brandInventory'
export default {
  data () {
    return {
      search: '',
      loading: false,
      isShow: false,
      selectLabel: {
        label: '推荐',
        value: '推荐',
        name: ''
      },
      labelList: [
        {
          label: '推荐',
          value: '推荐',
          name: ''
        },
        {
          label: '餐饮',
          value: '1203',
          name: '餐饮'
        }, {
          label: '家电数码',
          value: '1206',
          name: ''
        }, {
          label: '休闲娱乐',
          value: '1207',
          name: '休闲娱乐'
        }, {
          label: '服饰皮革',
          value: '1208',
          name: '服装'
        }, {
          label: '奢侈品',
          value: '1209',
          name: ''
        }, {
          label: '生活服务',
          value: '1210',
          name: '生活服务'
        }, {
          label: '体育运动',
          value: '1215',
          name: '体育运动'
        }, {
          label: '儿童亲子',
          value: '1216',
          name: '儿童亲子'
        }, {
          label: '珠宝首饰',
          value: '1211',
          name: ''
        }, {
          label: '化妆品',
          value: '1212',
          name: ''
        }, {
          label: '快时尚',
          value: '1217',
          name: '时尚精品'
        }, {
          label: '集合店',
          value: '1218',
          name: '跨界集合店'
        }, {
          label: '文创',
          value: '1219',
          name: '文化艺术'
        }, {
          label: '其他品牌',
          value: '1214',
          name: '其他'
        }
      ],
      pageSize: 15,
      pageNum: 1,
      tendencyList: [],
      newsFocus: [],
      brandList: []
    }
  },
  components: {
    brandTop,
    newsItem,
    brandTab,
    brandInventory
  },
  created () {
    this.loading = true
    this.getNewsFoucs()
    this.getTendencyList()
    // this.getBrand()
  },
  mounted () {

  },
  methods: {
    // 获取焦点图
    getNewsFoucs () {
      this.axios.post(api.getNewsFocus)
        .then((res) => {
          if (res.data.code === 0) {
            this.newsFocus = res.data.data
          }
        })
    },
    getTendencyList () {
      this.axios.post(api.getNewsBrandNewsSearch, {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        category: this.selectLabel.value,
        keyWord: ''
      })
        .then((res) => {
          this.loading = false
          if (res.data.code === 0) {
            this.tendencyList = res.data.data.newsListVos
          }
        })
    },
    //
    toDetail (detail) {
      const brandTendencyDetail = this.$router.resolve({
        path: '/brandTendencyDetail',
        query: {
          title: encodeURIComponent('商业洞察列表'),
          newsId: detail.newsId
        }
      })
      window.open('/' + brandTendencyDetail.href, '_blank')
    },
    // 更多资讯
    toList () {
      const page = this.$router.resolve({
        path: '/brandTendencyList',
        query: {
        }
      })
      window.open('/' + page.href, '_blank')
    },
    // 搜索
    searchHandle () {
      if (!this.search) {
        this.$message({
          type: 'warning',
          message: '请输入你要搜索的关键词！',
          duration: 3000
        })
      } else {
        const page = this.$router.resolve({
          path: '/brandTendencySearch',
          query: {
            search: this.search
          }
        })
        window.open('/' + page.href, '_blank')
      }
    },
    toBrandDetail (detail) {
      const page = this.$router.resolve({
        path: '/brandDetail',
        query: {
          id: detail.brandId,
          source: 1,
          gbid: detail.gbid,
          questionState: 0
        }
      })
      window.open('/' + page.href, '_blank')
    },
    hideAndShowLabel () {
      this.isShow = !this.isShow
    },
    selectHandle (data) {
      this.selectLabel = data
      this.getTendencyList()
      // this.getBrand()
    },
    getBrand () {
      const params = {
        pageNum: 1,
        brand_name: '',
        loading: true,
        brand_label: '',
        operation_feature: '',
        inCityIds: '',
        benchmark_shop_num: '',
        province_id: '',
        format_tagname: '',
        benchmark_floor_preference: '',
        attribute_formats_id: this.selectLabel.name,
        attribute_founded_id: '',
        attribute_kaidian_id: '',
        attribute_consumption_id: '',
        demand_plan_shop_num: '',
        demand_plan_expand_city: '',
        demand_area: this.areaId,
        demand_plan_expand_province: '',
        other_contact: '',
        customLabel: '',
        brandGrades: '',
        brandClass: '',
        cooperationship: '',
        addUid: 0,
        menuFilter: 9100,
        dataType: '',
        pageSize: 4,
        radio_type: 9001
      }
      return this.axios.post(api.selfBrandList, params)
        .then((res) => {
          this.loading = false
          if (res.data.code === 0) {
            if (res.data.data.dataList.length > 0) {
              this.brandList = []
              res.data.data.dataList.forEach(item => {
                this.getBrandNews(item)
              })
            }
          }
        })
    },
    getBrandNews (brand) {
      this.axios.post(api.getNewsBrandNewsSearch, {
        pageNum: 1,
        pageSize: 3,
        category: '',
        keyWord: brand.brandName
      })
        .then((res) => {
          this.loading = false
          if (res.data.code === 0) {
            const newsList = res.data.data.newsListVos
            this.brandList.push({ ...brand, newsList: newsList })
          }
        })
    }
  }
}
</script>

<style lang="stylus" scoped>
.mb-20
  margin-bottom 20px
.tendency-wrap
  margin 0 auto
  width 1280px
  color #ffffff
  margin-top 24px
  padding-bottom 24px
.tendency-content
  display flex
  .tendency-left
    width 808px
    margin-right 23px
  .tendency-right
    width 450px
.tendency-search
  margin-bottom 25px
  display flex
  input
    line-height 40px
    height 40px
    background rgba(39, 41, 48, 1)
    border 1px solid transparent
    outline none
    color #ffffff
    font-size 14px
    padding 0 21px
    flex 1
  span
    color #ffffff
    font-size 14px
    width 113px
    background rgba(62, 62, 69, 1)
    display inline-block
    line-height 40px
    height 40px
    text-align center
    i
      font-size 17px
      margin-right 6px
      margin-left -18px
      vertical-align middle
      color #ffffff
      &:before
        content '\E741'
        color #ffffff
.tendency-Carousel
  margin-bottom 25px
  border-radius 4px
  overflow hidden
.tendency-Carousel-item
  position relative
  height 336px
  cursor pointer
  img
    width 100%
    height 100%
  div
    height 120px
    width 100%
    position absolute
    bottom 0
    left 0px
    background linear-gradient(180deg, rgba(15, 15, 15, 0) 0%, rgba(0, 0, 0, 1) 100%)
  p
    color #ffffff
    font-size 18px
    position absolute
    bottom 22px
    left 20px
    line-height 24px
    font-weight 500
    width 85%
.tendency-article
  background-color #272930
  border-radius 2px
  padding-top 25px
.tendency-title
  position relative
  font-size 18px
  color #fff
  font-weight 500
  margin-left 20px
  padding-left 10px
  margin-bottom 20px
  i
    position absolute
    left 0
    top 3px
    width 2px
    height 18px
    background #ffa134
.tendency-label
  margin 0 65px 0px 10px
  position relative
  li.tendency-label-text
    display inline-block
    height 30px
    line-height 30px
    text-align center
    color #ffffff
    font-size 14px
    border-radius 2px
    border 1px solid rgba(255, 255, 255, 1)
    min-width 78px
    margin-left 10px
    margin-bottom 10px
    cursor pointer
    &.active
      background-color #FFA134
      border-color #FFA134
    &:hover
      background-color #FFA134
      border-color #FFA134
  .tendency-label-switch
    position absolute
    right -55px
    top 0
    height 30px
    line-height 30px
    text-align center
    font-size 12px
    border none
    min-width 60px
    cursor pointer
    color #999999
    i
      color #999999
      font-size 14px
      margin-right 6px
.brand-tendency-list
  display flex
  padding 0 25px
  margin 10px 0
  justify-content center
  .brand-item
    background-color #3F4046
    border-radius 2px
    width 368px
    &:last-child
      margin-left 20px
  .brand-item-info
    display flex
    align-items center
    margin 20px 24px
    .brand-img
      border 1px solid #999
      height 55px
      width 75px
      display flex
      align-items center
      img
        width 100%
        height auto
    h4
      margin-left 12px
      font-size 16px
      line-height 20px
      cursor pointer
  p
    color #CECECF
    font-size 12px
    line-height 17px
    display flex
    justify-content space-between
    align-items center
    border-top 1px solid #4E4E4E
    height 60px
    cursor pointer
    span
      display inline-block
      padding 10px 22px
      overflow hidden
      white-space nowrap
      width 320px
      text-overflow ellipsis
    i
      color #666666
      font-size 10px
      margin-right 15px
      transform scale(0.8)
.see-more
  font-size 15px
  line-height 60px
  text-align center
  color #999
  span
    cursor pointer
</style>
