<template>
  <div
    class="brand-inver"
    v-loading="loading"
  >
    <div class="brand-title">
      <h2 class="tendency-title"><i></i>品牌盘点</h2>
      <div
        class="see-more pointer"
        @click="toList"
      >查看更多</div>
    </div>
    <div class="brand-inver-list">
      <ul>
        <li
          v-for="item in list"
          :key="item.newsId"
          @click="toDetail(item)"
          class="pointer"
        >
          <img :src="item.firstPic">
          <p>{{item.title}}</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import api from '@/api'
export default {
  data () {
    return {
      list: [],
      loading: false
    }
  },
  components: {
  },
  created () {
    this.loading = true
    this.getList()
  },
  methods: {
    getList () {
      this.axios.post(api.getNewsBrandCheck, {
      })
        .then((res) => {
          this.loading = false
          if (res.data.code === 0) {
            this.list = res.data.data
          }
        })
    },
    toDetail (detail) {
      const brandTendencyDetail = this.$router.resolve({
        path: '/brandTendencyDetail',
        query: {
          title: encodeURIComponent('品牌盘点'),
          newsId: detail.newsId
        }
      })
      window.open('/' + brandTendencyDetail.href, '_blank')
    },
    toList () {
      const page = this.$router.resolve({
        path: '/brandTendencyList',
        query: {
          type: 'Inventory'
        }
      })
      window.open('/' + page.href, '_blank')
    }
  }
}
</script>

<style lang="stylus" scoped>
.brand-inver
  background-color #272930
.brand-title
  display flex
  justify-content space-between
  align-items center
  padding 20px
  div.see-more
    border 1px solid #FFA134
    color #FFA134
    font-size 12px
    line-height 23px
    text-align center
    width 70px
.tendency-title
  position relative
  font-size 18px
  color #fff
  font-weight 500
  padding-left 10px
  i
    position absolute
    left 0
    top 3px
    width 2px
    height 18px
    background #ffa134
.brand-inver-list
  border-top 1px solid #666
  padding 18px 20px 20px 0
  ul
    display flex
    flex-flow wrap
  li
    width 190px
    margin-bottom 25px
    margin-left 20px
    img
      display inline-block
      width 100%
      height 110px
      border-radius 2px
    p
      margin-top 10px
      color rgba(255, 255, 255, 0.8)
      font-size 14px
      line-height 20px
</style>
